import React from 'react'

const WhygravityLeadership = () => {
    return (
        <>
            <div className='WhygravityLeadership-parent'>
                <div className='WhygravityLeadership-absoluteimage'>
                    <img src="/leaderLayer.svg" alt="" />
                </div>
                <div className='WhygravityLeadership-sub-parent'>
                    <div className="row">
                        <div className="col-md-5">
                            <div className='WhygravityLeadership-left'>
                                <div className='WhygravityLeadership-left-sub1'>
                                    <h2>Leadership</h2>
                                    <p>Born to farmer parents in a small town of Devanahalli, Surya N.C. started his career in 1986 in the exports industry, and from there, shifted to serving as a real estate agent. The world of homes was new to him, and he focused on creating a better life. Closely observing the booming apartment culture, he saw great potential in the villa and villaments sector that opened up a new opportunity and provided him with much scope for innovation, inspiring him to start his journey as an entrepreneur with Gravity in 2008.</p>
                                </div>
                                <div className='WhygravityLeadership-left-sub2'>
                                    <p>“We all know that home is a feeling. When your home inspires you, motivates you, and fuels your well-being, that's when you know you've found something truly special." </p>
                                    <p>-- Surya NC</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-7 col-lg-6">
                            <div className='WhygravityLeadership-right'>
                                <div className="row">
                                    <div className="col-sm-6 col-md-6 col-lg-5">
                                        <div className="card">
                                            <div className='WhygravityLeadership-right-sub1'>
                                                <img src="/leader1.png" alt="" />
                                            </div>
                                            <div className="card-body">
                                                <p>Surya NC</p>
                                                <span> —  Founder CEO</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-5">
                                        <div className="card">
                                            <div className='WhygravityLeadership-right-sub1'>
                                                <img src="/leader2.png" alt="" />
                                            </div>
                                            <div className="card-body">
                                                <p>Namitha Surya</p>
                                                <span> — Director</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default WhygravityLeadership