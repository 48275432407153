import React from 'react';
import { Pagination, A11y,Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Link } from 'react-router-dom';

const TestimonialSwiper2 = () => {
    const blogsData = [{
        text:"It's a Namma area! We would say, don't look for anything else, first visit, experience, you'll not get such good developers and locality where they choose to build the property. Definitely you'll miss it if you don't go for it.",
        userImage:"/client.png",
        user:"Guruprasad",
        userid1:"IT Professional",
        userid2:"Community, Gravity Aranya"
    },
    {
        text:"I was given more freedom and flexibility while designing and adding customization,this is something no other build gave me as a choice. They were very accommodating.",
        userImage:"/client.png",
        user:"Rashmi & Manoj Nayak",
        userid1:"Entrepreneur and Aerospace Engineer",
        userid2:"1st Community Member, Gravity Aranya"
    },
    {
        text:"Whatever we asked, they were very open and transparent,they answered all our questions honestly and patiently and that helped build trust and made our choice of choosing Gravity as our builder, very quick.",
        userImage:"/client.png",
        user:"Somu Sharma",
        userid1:"IT Professional",
        userid2:"Community, Gravity Forest View"
    },
    {
        text:"We want as much greenery to be preserved, and we are having a forest close to our homes, Turahalli, address also, we say like, forest, so it is feeling more of a village, no traffic, less pollution, it’s nice.",
        userImage:"/client.png",
        user:"Narahari",
        userid1:"Buisness man",
        userid2:"Community, Gravity Aranya"
    }

    ];

    return (
        <>
            <div className='TestimonialSwiper2-mainparent' style={{ height: "450px" }}>
                <div className='TestimonialSwiper2-subparent'>
                    <Swiper
                        modules={[Pagination, A11y,Autoplay]}
                        spaceBetween={20}
                        slidesPerView={1}
                        loop={true}
                        autoplay = {{
                            delay: 8000,
                            disableOnInteraction:false
                        }}
                        direction={'vertical'}
                        pagination={{ clickable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <br /><br />
                        {blogsData.map((blog, index) => (
                            <SwiperSlide key={index}>
                                <div className='TestimonialSwiper2-card'>
                                    <div className="card">
                                        <div className='TestimonialAbsolute'>
                                            <img src="/testimonial.svg" alt="" />
                                        </div>
                                        <div className='TestimonialSwiper2-badge'>
                                            <img src="/testimonialbadge.png" alt="" />
                                        </div>
                                        <p>{blog.text} </p>
                                        <hr />
                                        <div className='Testimonial-card-footer'>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <div className='testimonial-client-image'>
                                                    <img src={blog.userImage} alt="" />
                                                </div>
                                                <p className='m-0'>{blog.user} </p>
                                                
                                            </div>
                                              
                                            <div className='Testimonial-Ratings'>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }} ></i>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }}></i>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }}></i>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }}></i>
                                                <i class="fa-solid fa-star" style={{ color: "#FFC700" }}></i>
                                            </div>
                                        </div>
                                        <div className="extra-info">
                                            <p>{blog.userid1}</p>
                                            <p>{blog.userid2}</p>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>

        </>
    );
};

export default TestimonialSwiper2;
