import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phonenumber: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = "Name is required";
    if (!formData.email.trim()) errors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = "Email is invalid";
    if (!formData.subject.trim()) errors.subject = "Subject is required";
    if (!formData.phone.trim()) errors.phone = "Phone number is required";
    if (!/^\d{10}$/.test(formData.phone))
      errors.phone = "Phone number is invalid";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setModalMessage("Your message has been sent successfully!");
    } else {
      setModalMessage("Please correct the errors in the form");
    }
    setErrors(formErrors);
    setShowModal(true);
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlInput = document.getElementById('currentUrlInput');
    if (urlInput) {
        urlInput.value = currentUrl;
    }
}, []);

  return (
    <>
      <div className="ContactForm-main-parent">
        <div className="container">
          <div className="ContactForm-sub-parent">
            <div className="row">
              <div className="col-md-4">
                <div className="ContactForm-sub-left">
                  <div className="ContactForm-icon-parent">
                    <div className="ContactForm-icon">
                      <img src="/mailicon.svg" alt="" />
                    </div>
                    <p>
                      <a href="mailto:marketing@gravityhomes.in">
                        marketing@gravityhomes.in
                      </a>
                    </p>
                  </div>
                  <div className="ContactForm-icon-parent">
                    <div className="ContactForm-icon">
                      <img src="/phoneicon.svg" alt="" />
                    </div>
                    <p>
                      <a href="tel:(+91) 95389 44994">(+91) 95389 44994</a>
                    </p>
                  </div>
                  <div className="ContactForm-icon-parent">
                  <div className="ContactForm-icon">
                      <img src="/globeicon.svg" alt="" />
                    </div>
                    <p>
                      <a href="www.yourdomain.com">
                        Gravity Homes #18B, Bharat Apartments 3rd floor, 5th
                        Main Road 39th Cross, 5th Block Jayanagar,Bengaluru
                        Karnataka 560041
                      </a>
                    </p>
                  </div>
                  <div className="contactform-SocialLinks">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/GravityHomes/"
                    >
                      <img src="/facebookcontact.svg" alt="" />
                    </a>
                    <a target="_blank" href="https://x.com/HomesGravity">
                      <img src="/twitter.svg" alt="" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@GravityHomes"
                    >
                      <img src="/youtube.png" alt="" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/gravity-homes-top-construction-company-in-bangalore/"
                    >
                      <img src="/linkedin-contact.svg" alt="" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/gravityhomesin/"
                    >
                      <img src="/instagram.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <form action="https://www.gravityhomes.in/forms/contact.php" method='post'>
                  <div className="ContactForm-sub-right">
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name *"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                        {errors.name && (
                          <p className="error text-danger">{errors.name}</p>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="email"
                          placeholder="Email *"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <p className="error text-danger">{errors.email}</p>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="subject"
                          placeholder="City"
                          value={formData.subject}
                          onChange={handleInputChange}
                        />
                        {errors.subject && (
                          <p className="error text-danger">{errors.subject}</p>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="phonenumber"
                          placeholder="Phone *"
                          value={formData.phonenumber}
                          onChange={handleInputChange}
                        />
                        {errors.phone && (
                          <p className="error text-danger">{errors.phone}</p>
                        )}
                      </div>
                      <div className="col-md-12">
                        <textarea
                          name="message"
                          placeholder="Hello I am interested in..."
                          value={formData.message}
                          onChange={handleInputChange}
                        />
                      </div>
                      <input type="hidden" id="currentUrlInput" name="currentUrl" />
                      <div className="Sendnowbutton">
                        <button type="submit" name="submit">Send Now ➜ </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="GravityHomes-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.794317177712!2d77.5783149750756!3d12.920937287389776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15758c33b66b%3A0x68e436780345b792!2sGravity%20Homes!5e0!3m2!1sen!2sin!4v1721306368387!5m2!1sen!2sin"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ContactForm;
