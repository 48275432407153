import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import HomeNavbar from './HomeNavbar'
import { Modal } from 'antd';
import ContactFormModal from './Contactus/ContactFormModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard, Autoplay } from 'swiper/modules';
import SwiperCore from 'swiper/core';

SwiperCore.use([Autoplay, Mousewheel, Keyboard]);

const HomeBanner = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div className='HomeBanner-parent'>
                <HomeNavbar />
                <div className="container-fluid">
                    <div className='HomeBanner-sub-parent'>
                        <div className='Bannerbackground-image' style={{ backgroundImage: `url('/Line pattern.png')` }}>

                        </div>
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-4">
                                <div className='HomeBanner-title-text'>
                                    <div className="HomeBanner-title">
                                        <h1>Experience the epitome of home comfort.</h1>
                                    </div>
                                    {/* <p>At Gravity Homes, we’re passionate about building homes that inspire. Discover your ideal living space where thoughtful design and modern amenities come together seamlessly.</p> */}
                                    <Link to="#" onClick={showModal}>
                                        <div className='BannerbuttonImage-parent' >
                                            <div className='BannerbuttonImage'>
                                                <img src="/bannerhome.svg" alt="" />
                                            </div>
                                            <span>Schedule Site Visit</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className='HomeBanner-image-main'>
                                    <div className='bannerabsoluteicon' role="button" onClick={showModal}>
                                        <img src="/bannerarrow.png" alt="" />
                                    </div>
                                    {/* <img src="/previous2.png" alt="" /> */}
                                    <Swiper
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        loop={true}
                                        // pagination={{ clickable: true }}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay, Mousewheel, Keyboard]}
                                        style={{ height: '335px' }}
                                        className='banner-swiper'
                                    >
                                        <SwiperSlide>
                                            <img src="/Living Palette_1.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/Living Palette_2.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/Living Palette_3.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/h1.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/interior1.webp" alt="" />
                                        </SwiperSlide>
                                       
                                        <SwiperSlide>
                                            <img src="/h2.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/h3.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/skyforest.jpg" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/estate2.jpg" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/banner_2.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/Project_smera_banner.webp" alt="" />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <img src="/grvityaranyaupdate.jpeg" alt="" />
                                        </SwiperSlide>
                                    </Swiper>
                                    {/* <div className='HomeBanner-image-main-sub'>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='HomeBanner-linepattern'>
                <img src="/Line pattern.png" alt="" />
            </div>

            <ContactFormModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
        </>

    )
}

export default HomeBanner