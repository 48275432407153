import React from 'react'

const AboutUs = () => {
    return (
        <>
            <div className='AboutUs-main-parent'>
                <div lassName='AboutUs-sub-parent'>
                    <div className='TopSection'>
                        <div className='EmptyLine'>
                        </div>
                        <p>Our goal is to build sustainable and visually appealing structures that coexist with nature, promoting seamless interaction between the environment and people while preserving natural beauty.</p>
                    </div>
                    <div className='AboutUs-mainSection'>
                        <div className="row">
                            {/* <div className="col-md-6">
                                <div className='AboutUs-mainSection-image'>
                                    <img src="/aboutsectionimage.png" alt="" />
                                </div>
                                <div className='location-icon'>
                                    <img src="/area.png" alt="" />
                                    <p>BANGALORE, INDIA</p>
                                </div>
                            </div> */}
                            <div className="col-md-6">
                                <div className='AboutSection-right'>
                                    <div className='AboutSection-right-sub1'>
                                        <h2>About Us</h2>
                                        <p>A Shared Vision For Sustainable Living:</p>
                                        <span>With 12 projects, 30 acres of expansive landscape, and 300+ homes designed for luxury living in Bangalore, we are happy to count these numbers and all the more, your appreciation that follows. Thank you for receiving Gravity Living with so much warmth and embracing our projects to be your homes.</span>
                                    </div>
                                    <div className='AboutSection-right-sub2'>
                                        <div className='AboutSection-right-sub2-inner'>
                                            <h2>Why Choose Us</h2>
                                            <div className='d-flex gap-3'>
                                                <img className='CheckCircle-image' src="/check-circle.svg" alt="" />
                                                <div>
                                                    <p>Over 2,000+ Satisfied Clients</p>
                                                    <span>Our track record speaks for itself - with years of experience under our belt, we have had the pleasure of serving over 2,000+ clients and counting.</span>
                                                </div>
                                            </div>
                                            <div className='d-flex gap-3'>
                                                <img className='CheckCircle-image' src="/check-circle.svg" alt="" />
                                                <div>
                                                    <p>Certified and Trusted</p>
                                                    <span>As a certified and trusted Real Estate Company, we provide exceptional services and have a reputation for delivering high-quality results</span>
                                                </div>
                                            </div>
                                            <div className='d-flex gap-3'>
                                                <img className='CheckCircle-image' src="/check-circle.svg" alt="" />
                                                <div>
                                                    <p>Eco-Friendly Architecture</p>
                                                    <span>Embark on a visual journey along the environment, and witness firsthand the beauty of architectural designs interwoven by nature and human imagination</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className='AboutSection-right'>
                                    <div className='AboutSection-right-sub1'>
                                        <h2>Mission and Vission</h2> 
                                        <p>A Shared Mision For Sustainable Living:</p>
                                     <span>Our mission at Gravity Homes is to enrich lifestyles—a relentless commitment to design a unique sense of belonging
                                     within the prime green of Bangalore</span><br /><br />
                                     <span>
                                     Our vision at Gravity Homes is to stand at the forefront
of eco-conscious luxury property development, recognised by our unwavering dedication towards community-centric design driven by eco-innovation. We aim to
build new benchmarks and redefine industry standards
within the real estate sector
                                     </span>
                                  </div>
                                    <div className='AboutSection-right-sub2'>
                                        <div className='AboutSection-right-sub2-inner'>
                                            <h2>Why We're the Best Choice</h2>
                                            <div className='d-flex gap-3'>
                                                <img className='CheckCircle-image' src="/check-circle.svg" alt="" />
                                                <div>
                                                    <p>Eco-Conscious Luxury: </p>
                                                <span>Gravity Homes leads the way in luxury property development with a strong focus on environmental sustainability, ensuring that every property is designed with ecological responsibility in mind.</span>
                                                </div>
                                            </div>
                                            <div className='d-flex gap-3'>
                                                <img className='CheckCircle-image' src="/check-circle.svg" alt="" />
                                                <div>
                                                    <p>Harmonious Integration with Nature:</p>
                                                <span>Gravity Homes prioritizes designs that harmonize with the natural environment, maintaining a balance between modern living and ecological preservation.</span>
                                                </div>
                                            </div>
                                            <div className='d-flex gap-3'>
                                                <img className='CheckCircle-image' src="/check-circle.svg" alt="" />
                                                <div>
                                                    <p>Fostering a Sense of Belonging: </p>
                                                <span>Gravity Homes aims to cultivate a strong sense of community and belonging, creating environments where residents feel connected and valued within their neighborhoods.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="col-md-6">

                            </div> */}
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default AboutUs