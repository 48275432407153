import React, { useEffect } from 'react'
import './Projects.css'
import ProjectsBanner from './ProjectsBanner'
import OnGoingProjects from './OnGoingProjects'
import UpcomingProjects from './UpcomingProjects'
import PreviousProjects from './PreviousProjects'
// import WhyUs from './WhyUs'
import HomeNavbar from '../HomeNavbar'
import Footer from '../Footer'
import { Helmet } from 'react-helmet-async'
const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Helmet>
      <title>Gravity Homes - Ongoing, Upcoming and Completed Projects in Bangalore</title>
      <meta name='description' content='Explore Gravity s premium real estate ongoing, upcoming and completed projects in Bangalore Find your dream home with our meticulously designed living spaces' data-rh="true" />
    </Helmet>
      <div className='Projects-mainpage'>
        <HomeNavbar />
        <ProjectsBanner />
        <OnGoingProjects />
        <UpcomingProjects />
        {/* <WhyUs /> */}
        <PreviousProjects />
        
      </div>
      <Footer />
    </>
  )
}

export default Projects