import React, { useEffect, useState } from "react";
import "./Blogdetails.css";
import HomeNavbar from "../HomeNavbar";
import Footer from "../Footer";
import Blogsdata from "../JSON/Blogsdata.json";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { SlugPropertyDetails } from "../../reducers/propertyReducer";
import { Helmet } from "react-helmet-async";

const BlogDetails = () => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const slugDetails = useParams().slug;
  const dispatch = useDispatch();
  const [PropertyDetails, setPropertyDetails] = useState(null);

  const BlogsdataJSON = Blogsdata;

  useEffect(() => {
    if (slugDetails) {
      const data = BlogsdataJSON?.filter(
        (item) => item.slug === slugDetails
      )[0];
      setPropertyDetails(data);
      dispatch(SlugPropertyDetails(data));
    }
  }, [slugDetails, dispatch, BlogsdataJSON]);

  const propertyData = useSelector((state) => state?.property?.propertyData);

  // Filter out the current blog post
  const relatedPosts = BlogsdataJSON.filter(
    (post) => post.slug !== slugDetails
  ).slice(0, 3);

  return (
    <>
      <Helmet>
        <title>{PropertyDetails?.metaTitle || ""}</title>
        <meta
          name="description"
          content={PropertyDetails?.metaDescription || ""}
          data-rh="true"
        />
      </Helmet>
      <div className="BlogDetails-main-parent">
        <HomeNavbar />
        <div className="BlogDetailsInner-parent position-relative">
          <div className="Blogdetails-main-head">
            <div className="Blogdetails-img-main">
              <img src={PropertyDetails?.mainimage || ""} alt="" />
            </div>
            <div className="BlogDetails-Finibus-Bonorum-line-main">
              <div className="BlogDetails-Finibus-Bonorum-line-head-sub">
                {PropertyDetails?.title && <h2>{PropertyDetails.title}</h2>}
                <p>Written by Gravity Homes</p>
                <hr />
              </div>
              <div className="BlogDetails-Finibus-Bonorum-sub-lines">
                {PropertyDetails?.description && (
                  <p>{PropertyDetails.description}</p>
                )}
                {PropertyDetails?.subtitle1 && (
                  <h5>{PropertyDetails.subtitle1}</h5>
                )}
                {PropertyDetails?.description1 && (
                  <p>{PropertyDetails.description1}</p>
                )}
                {PropertyDetails?.listd && PropertyDetails.listd.length > 0 && (
                  <ul>
                    {PropertyDetails.listd.map((li, i) => (
                      <li
                        key={i}
                        style={{
                          textAlign: "left",
                          marginBottom: "8px",
                          color: "#45546d",
                        }}
                      >
                        <span dangerouslySetInnerHTML={{ __html: li.text }} />
                      </li>
                    ))}
                  </ul>
                )}
                {PropertyDetails?.subtitle2 && (
                  <h6>{PropertyDetails.subtitle2}</h6>
                )}
                {PropertyDetails?.description2 && (
                  <p>{PropertyDetails.description2}</p>
                )}
                {PropertyDetails?.subtitle3 && (
                  <h6>{PropertyDetails.subtitle3}</h6>
                )}
                {PropertyDetails?.description3 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: PropertyDetails.description3.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                )}

                {/* {PropertyDetails?.subtitle4 && <h6>{PropertyDetails.subtitle4}</h6>}
                {PropertyDetails?.description4 && <p>{PropertyDetails.description4}</p>}
                {PropertyDetails?.subtitle5 && <h6>{PropertyDetails.subtitle5}</h6>}
                {PropertyDetails?.description5 && <p>{PropertyDetails.description5}</p>}
                {PropertyDetails?.subtitle6 && <h6>{PropertyDetails.subtitle6}</h6>}
                {PropertyDetails?.description6sub1 && <p>{PropertyDetails.description6sub1}</p>}
                {PropertyDetails?.description6sub2 && <p>{PropertyDetails.description6sub2}</p>}
                {PropertyDetails?.description6sub3 && <p>{PropertyDetails.description6sub3}</p>} */}
                {PropertyDetails?.listd2 &&
                  PropertyDetails.listd2.length > 0 && (
                    <ul>
                      {PropertyDetails.listd2.map((li, i) => (
                        <li
                          key={i}
                          style={{
                            textAlign: "left",
                            marginBottom: "8px",
                            color: "#45546d",
                          }}
                        >
                          <span dangerouslySetInnerHTML={{ __html: li.text }} />
                        </li>
                      ))}
                    </ul>
                  )}
                {PropertyDetails?.description6sub1 && (
                  <p>{PropertyDetails.description6sub1}</p>
                )}
                {PropertyDetails?.subtitle4 && (
                  <h6>{PropertyDetails.subtitle4}</h6>
                )}
                {PropertyDetails?.description4 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: PropertyDetails.description4.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                )}
                {PropertyDetails?.description6sub2 && (
                  <h6>{PropertyDetails.description6sub2}</h6>
                )}
                {PropertyDetails?.listd3 &&
                  PropertyDetails.listd3.length > 0 && (
                    <ul>
                      {PropertyDetails.listd3.map((li, i) => (
                        <li
                          key={i}
                          style={{
                            textAlign: "left",
                            marginBottom: "8px",
                            color: "#45546d",
                          }}
                        >
                          <span dangerouslySetInnerHTML={{ __html: li.text }} />
                        </li>
                      ))}
                    </ul>
                  )}
                {PropertyDetails?.subtitle5 && (
                  <h6>{PropertyDetails.subtitle5}</h6>
                )}
               {PropertyDetails?.description5 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: PropertyDetails.description5.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                )}
                {PropertyDetails?.description6sub3 && (
                  <h6>{PropertyDetails.description6sub3}</h6>
                )}
                {PropertyDetails?.listd4 &&
                  PropertyDetails.listd4.length > 0 && (
                    <ul>
                      {PropertyDetails.listd4.map((li, i) => (
                        <li
                          key={i}
                          style={{
                            textAlign: "left",
                            marginBottom: "8px",
                            color: "#45546d",
                          }}
                        >
                          <span dangerouslySetInnerHTML={{ __html: li.text }} />
                        </li>
                      ))}
                    </ul>
                  )}
                {PropertyDetails?.subtitle6 && (
                  <h6>{PropertyDetails.subtitle6}</h6>
                )}
               {PropertyDetails?.description6 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: PropertyDetails.description6.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                )}
                {PropertyDetails?.subtitle7 && (
                  <h6>{PropertyDetails.subtitle7}</h6>
                )}
               {PropertyDetails?.description7 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: PropertyDetails.description7.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                )}
                {PropertyDetails?.subtitle8 && (
                  <h6>{PropertyDetails.subtitle8}</h6>
                )}
               {PropertyDetails?.description8 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: PropertyDetails.description8.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                )}
                {PropertyDetails?.subtitle9 && (
                  <h6>{PropertyDetails.subtitle9}</h6>
                )}
                {PropertyDetails?.description9 && (
                  <p>{PropertyDetails.description9}</p>
                )}
                {PropertyDetails?.subtitle10 && (
                  <h6>{PropertyDetails.subtitle10}</h6>
                )}
                {PropertyDetails?.description10 && (
                  <p>{PropertyDetails.description10}</p>
                )}

                {PropertyDetails?.conclusion && (
                  <h3>{PropertyDetails?.conclusion.title} </h3>
                )}
                {PropertyDetails?.conclusion && (
                  <p>{PropertyDetails?.conclusion.description} </p>
                )}
                {PropertyDetails?.conclusion && (
                  <p>{PropertyDetails?.conclusion.description1} </p>
                )}
              </div>
            </div>
            <div className="Gavityblogpage-popular-word">
              <h5>Related Blogs</h5>
            </div>
            <div className="row Gavityblogpage-Articles-News-main">
              {relatedPosts.map((post) => (
                <div key={post.id} className="col-lg-4 col-sm-6 col-md-6">
                  <Link to={`/blog/${post.slug}`}>
                    <div className="Gavityblogpage-Articles-card-main">
                      <div className="Gavityblogpage-Articles-card-img">
                        <img src={post.mainimage || ""} alt="" />
                        <h6 className="blogs-category-badge">
                          {post.category || ""}
                        </h6>
                      </div>
                      <h4>
                        {post.title ? post.title.slice(0, 60) + "..." : ""}
                      </h4>
                      <div className="Gavityblogpage-Articles-card-date-and-arrow">
                        <p>{post.date || ""}</p>
                        <div className="Gavityblogpage-block-two-popular-arrow-logo-two">
                          <Link to={`/blog/${post.slug}`}>
                            <img src="/gavityphotos/Arrowimg.svg" alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className="BlogDetails-scrollTop" onClick={scrollTop}>
              <img src="/scrolltop.svg" alt="" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlogDetails;

// import React, { useEffect, useState } from 'react'
// import './Blogdetails.css';
// import HomeNavbar from '../HomeNavbar';
// import Footer from '../Footer';
// import Blogsdata from "../JSON/Blogsdata.json"
// import { useDispatch, useSelector } from 'react-redux'
// import { Link, useParams } from 'react-router-dom'
// import { SlugPropertyDetails } from '../../reducers/propertyReducer'
// import { Helmet } from 'react-helmet-async';

// const BlogDetails = () => {
//   const scrollTop = () => {
//     window.scrollTo({ top: 0, behavior: "smooth" })
//   }
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])

//   const slugDetails = useParams().slug;
//   const dispatch = useDispatch();
//   const [PropertyDetails, setPropertyDetails] = useState(null)
//   console.log("PropertyDetails", PropertyDetails)
//   const BlogsdataJSON = Blogsdata;

//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [slugDetails])

//   useEffect(() => {
//     if (slugDetails) {
//       const data = BlogsdataJSON?.filter(item => item.slug === slugDetails)[0]
//       setPropertyDetails(data)
//       dispatch(SlugPropertyDetails(data))
//     }
//   }, [slugDetails, dispatch, BlogsdataJSON])

//   const propertyData = useSelector(state => state?.property?.propertyData)

//   // Filter out the current blog post
//   const relatedPosts = BlogsdataJSON.filter(post => post.slug !== slugDetails).slice(0, 3);

//   return (
//     <>
//       <Helmet>
//         <title>{PropertyDetails?.metaTitle}</title>
//         <meta name='description' content={PropertyDetails?.metaDescription} data-rh="true" />
//       </Helmet>
//       <div className="BlogDetails-main-parent ">
//         <HomeNavbar />
//         <div className='BlogDEtailsInner-parent position-relative'>
//           <div className='Blogdetails-main-head '>
//             <div className="Blogdetails-img-main">
//               <img src={PropertyDetails?.mainimage} alt="" />
//             </div>
//             <div className="BlogDetails-Finibus-Bonorum-line-main">
//               <div className="BlogDetails-Finibus-Bonorum-line-head-sub">
//                 <h2>{PropertyDetails?.title} </h2>
//                 <p>Written by Gravity Homes</p>
//                 <hr />
//               </div>
//               <div className="BlogDetails-Finibus-Bonorum-sub-lines">
//                 <p>{PropertyDetails?.description} </p>
//                 <h5>{PropertyDetails?.subtitle1}</h5>
//                 <p>{PropertyDetails?.description1} </p>
//                 <h6>{PropertyDetails?.subtitle2}</h6>
//                 <p>{PropertyDetails?.description2}</p>
//                 <h6>{PropertyDetails?.subtitle3} </h6>
//                 <p>{PropertyDetails?.description3} </p>
//                 <h6>{PropertyDetails?.subtitle4}</h6>
//                 <p>{PropertyDetails?.description4}</p>
//                 <h6>{PropertyDetails?.subtitle5}</h6>
//                 <p>{PropertyDetails?.description5}</p>
//                 <h6>{PropertyDetails?.subtitle6}</h6>
//                 <p>{PropertyDetails?.description6sub1}</p>
//                 <p>{PropertyDetails?.description6sub2}</p>
//                 <p>{PropertyDetails?.description6sub3}</p>
//               </div>
//             </div>
//             {/* {PropertyDetails?.list &&  ( */}
//               <ul>
//                   {PropertyDetails?.listd.map((li, i) => (
//                 <li style={{ textAlign: "left"}} key={i}>
//                     {li.text}
//                 </li>
//                   ))}
//               </ul>
//             {/* )} */}
//             <div className="Gavityblogpage-popular-word">
//               <h5>Related Blogs</h5>
//             </div>
//             <div className="row Gavityblogpage-Articles-News-main">
//               {relatedPosts.map(post => (
//                 <div key={post.id} className="col-lg-4 col-sm-6 col-md-6">
//                   <Link to={`/blog/${post.slug}`}>
//                     <div className="Gavityblogpage-Articles-card-main">
//                       <div className="Gavityblogpage-Articles-card-img">
//                         <img src={post.mainimage} alt="" />
//                         <h6 className="blogs-category-badge">{post.category}</h6>
//                       </div>
//                       <h4>{post.title ? post.title.slice(0, 60) + "..." : post.title}</h4>
//                       <div className="Gavityblogpage-Articles-card-date-and-arrow">
//                         <p>{post.date}</p>
//                         <div className="Gavityblogpage-block-two-popular-arrow-logo-two">
//                           <Link to={`/blog/${post.slug}`}>
//                             <img src="/gavityphotos/Arrowimg.svg" alt="" />
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                   </Link>
//                 </div>
//               ))}
//             </div>
//             <div className='BlogDetails-scrollTop' onClick={scrollTop}>
//               <img src="/scrolltop.svg" alt="" />
//             </div>
//           </div>
//         </div>
//         <Footer />
//       </div>
//     </>
//   )
// }

// export default BlogDetails;
