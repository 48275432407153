import React from 'react'

const ProjectsBanner = () => {
    return (
        <>
            <div className='ProjectsBanner-main-parent'>
                <div className='ProjectsBanner-sub-parent'>
                    <div className='ProjectsBanner-bg'>
                        <div className='ProjectsBanner-content'>
                            <h1>Our Projects</h1>
                        </div>
                    </div>
                    <div className='ProjectsBanner-Banner-text' style={{backgroundImage:`url('/ProjectsBackground.svg')`}}>
                        <p>“Thank you for receiving Gravity Living with so much warmth, embracing our projects to be your homes.”</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectsBanner